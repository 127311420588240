module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/workspace/src/components/layouts/MDXLayout.tsx","blog":"/workspace/src/components/layouts/MDXBlogPostLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"none","maintainCase":false,"id":"data-anchor"}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/workspace"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-71737212-1","head":false,"anonymize":true,"respectDNT":true,"defer":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
