import 'typeface-noto-sans'
import 'typeface-nunito'
import 'typeface-inter'

import './src/css/main.css'
import './src/css/more.scss'
import './src/css/custom.css'

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

// https://flaviocopes.com/gatsby-load-external-js/
export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://fast.wistia.com/embed/medias/hrzq82losf.jsonp")
    addScript("https://fast.wistia.com/assets/external/E-v1.js")
  }
}
