import * as React from 'react'

export function Wistia({ id, style }: { id: string, style?: React.CSSProperties }) {
  return <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative', ...style }}>
    <div className="wistia_responsive_wrapper"
         style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
      <div className={`wistia_embed wistia_async_${id} seo=false videoFoam=true`}
           style={{ height: '100%', position: 'relative', width: '100%' }}>
        <div className="wistia_swatch" style={{
          height: '100%',
          left: 0,
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          transition: 'opacity 200ms',
          objectFit: 'contain',
          width: '100%'
        }}>
          <img src={`https://fast.wistia.com/embed/medias/${id}/swatch`}
               style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt=""
               aria-hidden="true"
            // onLoad="this.parentNode.style.opacity=1;"
          /></div>
      </div>
    </div>
  </div>
}

export function wistia_thumbnail(video_id: string) {
  return { __html: `<script src="https://fast.wistia.com/embed/medias/hrzq82losf.jsonp" async></script><script src="https://fast.wistia.com/assets/external/E-v1.js" async></script><div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><span class="wistia_embed wistia_async_${video_id} popover=true popoverAnimateThumbnail=true videoFoam=true" style="display:inline-block;height:100%;position:relative;width:100%">&nbsp;</span></div></div>` }
}

export function wistia_popover_button(video_id: string, content: React.ReactNode) {
  return <span className={`wistia_embed wistia_async_${video_id} popover=true popoverContent=link`}
               style={{ display: 'inline' }}>
    <a className="wistia-button" // #drGxk#
       href="#" role="button">
      <svg className="h-6 w-6 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
        clipRule="evenodd" />
</svg>
      &nbsp;{content}</a></span>
}
