// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-2021-issues-panel-mdx": () => import("./../../../src/pages/blog/2021/issues-panel.mdx" /* webpackChunkName: "component---src-pages-blog-2021-issues-panel-mdx" */),
  "component---src-pages-blog-2021-smart-contexts-mdx": () => import("./../../../src/pages/blog/2021/smart-contexts.mdx" /* webpackChunkName: "component---src-pages-blog-2021-smart-contexts-mdx" */),
  "component---src-pages-blog-2021-xcode-support-mdx": () => import("./../../../src/pages/blog/2021/xcode-support.mdx" /* webpackChunkName: "component---src-pages-blog-2021-xcode-support-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-documentation-add-an-image-mdx": () => import("./../../../src/pages/documentation/add-an-image.mdx" /* webpackChunkName: "component---src-pages-documentation-add-an-image-mdx" */),
  "component---src-pages-documentation-adding-a-diagram-mdx": () => import("./../../../src/pages/documentation/adding-a-diagram.mdx" /* webpackChunkName: "component---src-pages-documentation-adding-a-diagram-mdx" */),
  "component---src-pages-documentation-adding-live-code-views-mdx": () => import("./../../../src/pages/documentation/adding-live-code-views.mdx" /* webpackChunkName: "component---src-pages-documentation-adding-live-code-views-mdx" */),
  "component---src-pages-documentation-code-sources-mdx": () => import("./../../../src/pages/documentation/code-sources.mdx" /* webpackChunkName: "component---src-pages-documentation-code-sources-mdx" */),
  "component---src-pages-documentation-command-line-mdx": () => import("./../../../src/pages/documentation/command-line.mdx" /* webpackChunkName: "component---src-pages-documentation-command-line-mdx" */),
  "component---src-pages-documentation-creating-notes-mdx": () => import("./../../../src/pages/documentation/creating-notes.mdx" /* webpackChunkName: "component---src-pages-documentation-creating-notes-mdx" */),
  "component---src-pages-documentation-ide-plugins-mdx": () => import("./../../../src/pages/documentation/ide-plugins.mdx" /* webpackChunkName: "component---src-pages-documentation-ide-plugins-mdx" */),
  "component---src-pages-documentation-import-from-legacy-codestory-mdx": () => import("./../../../src/pages/documentation/import-from-legacy-codestory.mdx" /* webpackChunkName: "component---src-pages-documentation-import-from-legacy-codestory-mdx" */),
  "component---src-pages-documentation-index-tsx": () => import("./../../../src/pages/documentation/index.tsx" /* webpackChunkName: "component---src-pages-documentation-index-tsx" */),
  "component---src-pages-documentation-quick-tour-mdx": () => import("./../../../src/pages/documentation/quick-tour.mdx" /* webpackChunkName: "component---src-pages-documentation-quick-tour-mdx" */),
  "component---src-pages-documentation-save-push-publish-mdx": () => import("./../../../src/pages/documentation/save-push-publish.mdx" /* webpackChunkName: "component---src-pages-documentation-save-push-publish-mdx" */),
  "component---src-pages-documentation-saving-pushing-publishing-mdx": () => import("./../../../src/pages/documentation/saving-pushing-publishing.mdx" /* webpackChunkName: "component---src-pages-documentation-saving-pushing-publishing-mdx" */),
  "component---src-pages-documentation-sequence-diagrams-mdx": () => import("./../../../src/pages/documentation/sequence-diagrams.mdx" /* webpackChunkName: "component---src-pages-documentation-sequence-diagrams-mdx" */),
  "component---src-pages-documentation-sign-up-mdx": () => import("./../../../src/pages/documentation/sign-up.mdx" /* webpackChunkName: "component---src-pages-documentation-sign-up-mdx" */),
  "component---src-pages-documentation-unfeatured-notes-mdx": () => import("./../../../src/pages/documentation/unfeatured-notes.mdx" /* webpackChunkName: "component---src-pages-documentation-unfeatured-notes-mdx" */),
  "component---src-pages-downloads-mdx": () => import("./../../../src/pages/downloads.mdx" /* webpackChunkName: "component---src-pages-downloads-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-roadmap-mdx": () => import("./../../../src/pages/roadmap.mdx" /* webpackChunkName: "component---src-pages-roadmap-mdx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */)
}

