import { Apple } from '@styled-icons/boxicons-logos/Apple';
/*#69gfu#*/

import { Windows } from '@styled-icons/boxicons-logos/Windows';
import { Linux } from '@styled-icons/fa-brands/Linux';
import * as React from 'react';
export default {
  Apple,
  Windows,
  Linux,
  React
};